import { CreateGenerationCard } from '@/components/create-generation';
import Layout from '@/components/layout';

function GenerationPage() {
  return (
    <Layout auth>
      <div className="bg-gradient-to-t from-background to-primary-container w-full h-full mx-auto flex flex-col items-center gap-8 p-12 dark:from-neutral-accent dark:to-neutral-container justify-center">
        <CreateGenerationCard type="default" />
      </div>
    </Layout>
  );
}
export default GenerationPage;
